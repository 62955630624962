<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="12">
        <!-- แจ้งเตือนย้ายครู -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="
              periods.period_enable === '1' && periods.period_type === 'teacher'
            "
          >
            <v-row>
              <v-col cols="6">
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      <h2 class="primary--text">
                        <v-icon color="primary">mdi-account</v-icon>
                        ข้าราชการครู
                      </h2>
                      <h2>สามารถดำเนินการ <u>ยื่นคำร้องขอย้าย</u></h2>
                      <h3>
                        ก่อนระบบปิดในวันที่
                        {{
                          periods.period_stop
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
              <v-col cols="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                    {{
                      periods.period_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="periods.period_stop + ' 16:30:00'"
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="12">
        <base-material-card color="primary">
          <template v-slot:heading>
            <h2 class="h1 font-weight-light ">
              <v-icon large left>mdi-file-send</v-icon>การย้ายสายการสอน
            </h2>
          </template>

          <v-stepper v-model="steps" vertical>
            <v-stepper-step :complete="steps > 1" step="1">
              <div>
                ข้อมูลพื้นฐาน
                <small>
                  ข้อมูลเบื้องต้น รหัสรายการอ้างอิง {{ tp_id_ref }}</small
                >
                <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
              </div>
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-alert
                    border="left"
                    colored-border
                    color="green darken-1"
                    elevation="2"
                    type="info"
                  >
                    <h2>
                      ชื่อ-นามสกุล : {{ user.title_s }}{{ user.frist_name }}
                      {{ user.last_name }}
                    </h2>
                    <h3>รหัสบัตรประชาชน : {{ user.id_card }}</h3>
                    <h3>อายุ : {{ get_ageUser }}</h3>

                    <h3>เลขที่ตำแหน่ง : {{ user.id_position }}</h3>
                    <h3>วิทยฐานะ : {{ user.rang_name }}</h3>
                    <h3>สังกัด (สถานศึกษา) : {{ user.college_name }}</h3>

                    <h3>
                      วันที่เริ่มบรรจุเข้ารับราชการ :
                      {{ date_appoin_ch || "-" }}
                    </h3>
                    <h3>
                      ระยะเวลาตั้งแต่บรรจุเข้ารับราชการ : {{ get_gov_Age }}
                    </h3>
                  </v-alert>
                </v-col>

                <v-col cols="12" sm="6" align="center">
                  <v-alert
                    border="left"
                    colored-border
                    color="green darken-1"
                    elevation="2"
                    type="info"
                  >
                    <h2>
                      ครั้งที่ : {{ periods.period_times }} ประจำปี :
                      {{ periods.period_year }}
                    </h2>
                    <h3>เขียนที่ : {{ user.college_name }}</h3>
                    <h3>วันที่ยื่นคำร้อง : {{ date_today }}</h3>
                    <hr />
                    <div v-if="user.date_app_now === ''">
                      <v-alert prominent type="error">
                        <v-row>
                          <v-col class="grow">
                            <h3>
                              ให้งานบุคลากร วิทยาลัย
                              ดำเนินการเกี่ยวกับวันที่ปฏิบัติงาน ณ
                              สถานศึกษาปัจจุบันให้เรียบร้อย
                            </h3>
                          </v-col>
                        </v-row>
                      </v-alert>
                    </div>
                    <div v-else>
                      <h3>
                        วันที่เริ่มปฏิบัติหน้าที่ในสถานศึกษาปัจจุบัน :
                        {{
                          user.date_app_now
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                      <h2>ปฏิบัติหน้าที่เป็นเวลา : {{ get_Appoint_Age }}</h2>
                    </div>
                  </v-alert>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-alert
                    border="left"
                    colored-border
                    color="green darken-1"
                    elevation="2"
                    type="info"
                  >
                    <v-card>
                      <v-card-title class="mt-8">
                        <v-avatar size="56">
                          <v-icon large left>mdi-cast-education</v-icon>
                        </v-avatar>
                        <p class="ml-3">ประวัติการศึกษา</p>
                      </v-card-title>

                      <v-card-text>
                        <v-timeline align-top dense>
                          <v-timeline-item
                            v-for="item in personnel_educations"
                            :key="item.id_red"
                            small
                          >
                            <div>
                              <div class="font-weight-normal">
                                {{ item.education_level }} คณะวิชา :
                                {{ item.faculty_name }} สาขาวิชา :
                                {{ item.branch_name }} สำเร็จปีการศึกษา :
                                {{ item.year_finish }}
                              </div>
                            </div>
                          </v-timeline-item>
                        </v-timeline>
                      </v-card-text>
                    </v-card>
                  </v-alert>
                </v-col>
              </v-row>

              <v-btn
                @click="addtransference_personnel_38Submit()"
                color="primary"
              >
                ดำเนินการถัดไป
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="steps > 2" step="2">
              <div>
                มีความสมัครใจขอเปลี่ยนตำแหน่ง/ย้าย
                <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>                
              </div>
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-card class="pa-2">
                <v-row>
                  <v-col cols="12" md="12" class="pa-5">
                    <div class="font-weight-bold">
                      มีความสมัครใจขอเปลี่ยนตำแหน่ง/ย้าย/โอน
                      ไปแต่งตั้งให้ดำรงตำแหน่งบุคลากรทางการศึกษาอื่นตามมาตรา 38
                      ค.(2)
                    </div>

                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-autocomplete
                          :items="man_power_38"
                          item-text="agency"
                          item-value="mp_id_position"
                          v-model="
                            transference_personnel_38s.tp_positonid_select
                          "
                          outlined
                          label="ตำแหน่ง"
                          prepend-icon="mdi-notebook"
                          :rules="[v => !!v || '']"
                          @change="man_powerCk()"
                        >
                          <template v-slot:item="{ item }">
                            <div>
                              <div class="font-weight-bold">
                                {{ item.user_firstname }}
                                {{ item.user_lastname || "" }}
                              </div>
                              <div>
                                {{ item.mp_position }} :
                                {{ item.mp_id_position }}
                              </div>
                            </div>
                          </template>
                        </v-autocomplete>

                        <div class="text-center font-weight-bold red--text">
                          {{ man_power_38ck.user_firstname }}
                          {{ man_power_38ck.user_lastname }} :
                          {{ man_power_38ck.mp_id_position }} :
                          {{ man_power_38ck.mp_position }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn
                v-if="transference_personnel_38s.tp_positonid_select"
                color="primary"
                @click="updatetransference_personnel_38sSubmit((step = 2))"
              >
                ดำเนินการขั้นตอนถัดไป
              </v-btn>
              <v-btn
                outlined
                color="warning"
                text
                @click="deletetransference_personnel_38sSubmit()"
              >
                ยกเลิก
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="steps > 3" step="3">
              <div>
                เริ่มเข้ารับราชการ
                <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
              </div>
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-card class="pa-2">
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-card class="pa-2 ma-2">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="transference_personnel_38s.tp_govbegin"
                            :rules="[v => !!v || '']"
                            outlined
                            label="เป็นข้าราชการ"
                            prepend-icon="mdi-notebook"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="
                                  transference_personnel_38s.tp_govbegindate
                                "
                                label="เมื่อวันที่ :"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                :rules="[v => !!v || '']"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="
                                transference_personnel_38s.tp_govbegindate
                              "
                              @input="menu2 = false"
                              locale="th"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="
                              transference_personnel_38s.tp_govbeginposition
                            "
                            :rules="[v => !!v || '']"
                            outlined
                            label="ตำแหน่ง"
                            prepend-icon="mdi-notebook"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="
                              transference_personnel_38s.tp_govbeginlevel
                            "
                            :rules="[v => !!v || '']"
                            outlined
                            label="ระดับ"
                            prepend-icon="mdi-notebook"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="
                              transference_personnel_38s.tp_govbeginunder
                            "
                            outlined
                            label="สังกัด"
                            prepend-icon="mdi-account-network"
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="
                              transference_personnel_38s.tp_govbeginagency
                            "
                            outlined
                            label="ส่วนราชการ"
                            :rules="[v => !!v || '']"
                            prepend-icon="mdi-account-network"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="
                              transference_personnel_38s.tp_govbeginministry
                            "
                            outlined
                            label="กระทรวง"
                            :rules="[v => !!v || '']"
                            prepend-icon="mdi-account-network"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn
                color="primary"
                v-if="
                  transference_personnel_38s.tp_govbegin &&
                    transference_personnel_38s.tp_govbegindate &&
                    transference_personnel_38s.tp_govbeginposition &&
                    transference_personnel_38s.tp_govbeginlevel &&
                    transference_personnel_38s.tp_govbeginunder &&
                    transference_personnel_38s.tp_govbeginagency &&
                    transference_personnel_38s.tp_govbeginministry
                "
                @click="updatetransference_personnel_38sSubmit((step = 3))"
              >
                ดำเนินการขั้นตอนถัดไป
              </v-btn>
              <v-btn
                text
                outlined
                color="warning"
                @click="updatetransference_personnel_38sSubmit((step = 1))"
              >
                ยกเลิก
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="steps > 4" step="4">
              <div>
                วินัย/คดีความ
                <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
              </div>
            </v-stepper-step>

            <v-stepper-content step="4">
              <v-card class="pa-2">
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-row>
                      <v-alert
                        class="mx-auto justify-center pa-2 ma-2"
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                      >
                        <v-radio-group
                          row
                          v-model="transference_personnel_38s.tp_discipline"
                        >
                          <v-radio value="1">
                            <template v-slot:label>
                              <div>
                                <strong class="red--text"
                                  >เคยถูกลงโทษทางวินัย</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="0">
                            <template v-slot:label>
                              <div>
                                <strong class="success--text"
                                  >ไม่เคยถูกลงโทษทางวินัย</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>

                        <v-radio-group
                          row
                          v-model="transference_personnel_38s.tp_pro_discipline"
                        >
                          <v-radio value="1">
                            <template v-slot:label>
                              <div>
                                <strong class="red--text"
                                  >อยู่ในระหว่างถูกดำเนินการทางวินัย</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="0">
                            <template v-slot:label>
                              <div>
                                <strong class="success--text"
                                  >ไม่อยู่ในระหว่างถูกดำเนินการทางวินัย</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>

                        <v-radio-group
                          row
                          v-model="transference_personnel_38s.tp_criminal"
                        >
                          <v-radio value="1">
                            <template v-slot:label>
                              <div>
                                <strong class="red--text"
                                  >อยู่ในระหว่างถูกดำเนินคดีอาญา</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="0">
                            <template v-slot:label>
                              <div>
                                <strong class="success--text"
                                  >ไม่อยู่ในระหว่างถูกดำเนินคดีอาญา</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>

                        <v-radio-group
                          row
                          v-model="transference_personnel_38s.tp_bankrupt"
                        >
                          <v-radio value="1">
                            <template v-slot:label>
                              <div>
                                <strong class="red--text"
                                  >อยู่ในระหว่างถูกดำเนินคดีล้มละลาย</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="0">
                            <template v-slot:label>
                              <div>
                                <strong class="success--text"
                                  >ไม่อยู่ในระหว่างถูกดำเนินคดีล้มละลาย</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-alert>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn
                color="primary"
                @click="updatetransference_personnel_38sSubmit((step = 4))"
              >
                ดำเนินการขั้นตอนถัดไป
              </v-btn>
              <v-btn
                text
                outlined
                color="warning"
                @click="updatetransference_personnel_38sSubmit((step = 2))"
              >
                ยกเลิก
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="steps > 5" step="5">
              <div>
                เหตุผลการขอเปลี่ยนตำแหน่ง/ย้าย
                <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
              </div>
            </v-stepper-step>

            <v-stepper-content step="5">
              <v-card class="pa-2">
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-textarea
                      outlined
                      v-model="transference_personnel_38s.tp_reason"
                      :rules="[v => !!v || '']"
                      label="(ระบุ) : "
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn
                color="primary"
                v-if="transference_personnel_38s.tp_reason"
                @click="updatetransference_personnel_38sSubmit((step = 5))"
              >
                ดำเนินการขั้นตอนถัดไป
              </v-btn>
              <v-btn
                text
                outlined
                color="warning"
                @click="updatetransference_personnel_38sSubmit((step = 3))"
              >
                ยกเลิก
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="steps > 6" step="6">
              <div>
                ประวัติการศึกษา
                <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
              </div>
            </v-stepper-step>

            <v-stepper-content step="6">
              <v-card class="pa-2">
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-row>
                      <v-timeline align-top dense>
                        <v-timeline-item
                          v-for="item in personnel_educations"
                          :key="item.id_red"
                          small
                        >
                          <div>
                            <div class="font-weight-normal">
                              {{ item.education_level }} คณะวิชา :
                              {{ item.faculty_name }} สาขาวิชา :
                              {{ item.branch_name }} สำเร็จปีการศึกษา :
                              {{ item.year_finish }}
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn
                color="primary"
                v-if="personnel_education_check.count_ed > 0"
                @click="updatetransference_personnel_38sSubmit((step = 6))"
              >
                ดำเนินการขั้นตอนถัดไป
              </v-btn>
              <v-btn
                text
                outlined
                color="warning"
                @click="updatetransference_personnel_38sSubmit((step = 4))"
              >
                ยกเลิก
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="steps > 7" step="7">
              <div>
                ประวัติการรับราชการ

                <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
              </div>
            </v-stepper-step>
            <v-stepper-content step="7">
              <v-card class="pa-2">
                <v-row>
                  <v-col cols="12">
                    <v-timeline align-top dense>
                      <v-timeline-item
                        v-for="item in personnel_work_historys"
                        :key="item.id_red"
                        small
                      >
                        <div>
                          <div class="font-weight-normal">
                            {{
                              item.date_begin
                                | moment("add", "543 year")
                                | moment("D MMMM YYYY")
                            }}
                            ตำแหน่ง : {{ item.position_s }} สังกัด :
                            <span v-if="item.pwh_type === '0'">{{
                              item.college_name_work
                            }}</span>
                            <span v-else-if="item.pwh_type === '1'">{{
                              item.pwh_affiliation
                            }}</span>
                          </div>
                        </div>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                color="primary"
                v-if="countWorkHis > 0"
                @click="updatetransference_personnel_38sSubmit((step = 7))"
              >
                ดำเนินการขั้นตอนถัดไป
              </v-btn>
              <v-btn
                text
                outlined
                color="warning"
                @click="updatetransference_personnel_38sSubmit((step = 5))"
              >
                ยกเลิก
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="steps > 8" step="8">
              <div>
                ผลงานหรือผลการปฏิบัติงานที่ผ่านมา (ย้อนหลังไม่เกิน 5 ปี)
                <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
              </div>
            </v-stepper-step>

            <v-stepper-content step="8">
              <v-card class="pa-2">
                <v-row>
                  <v-col cols="12">
                    <v-timeline align-top dense>
                      <v-timeline-item
                        v-for="item in personnel_awards"
                        :key="item.id_red"
                        small
                      >
                        <div>
                          <div class="font-weight-normal">
                            {{
                              item.performance_time
                                | moment("add", "543 year")
                                | moment("D MMMM YYYY")
                            }}
                            : {{ item.performance_name }} :

                            <span v-if="item.award_level == 'college'"
                              >ระดับวิทยาลัย</span
                            >
                            <span v-if="item.award_level == 'provincial'"
                              >ระดับสำนักงานอาชีวศึกษาจังหวัด</span
                            >
                            <span v-if="item.award_level == 'sector'"
                              >ระดับภาค</span
                            >
                            <span v-if="item.award_level == 'vocational'"
                              >ระดับอาชีวศึกษา</span
                            >
                            <span v-if="item.award_level == 'nation'"
                              >ระดับชาติ</span
                            >
                            <span v-if="item.award_level == 'word'"
                              >ระดับโลก</span
                            >
                            :

                            <span v-if="item.type_performance == 'skill'"
                              >การแข่งขันทักษะ</span
                            >
                            <span v-if="item.type_performance == 'innovation'"
                              >การประกวดสิ่งประดิษฐ์</span
                            >
                            <span v-if="item.type_performance == 'award'"
                              >รางวัลเชิดชูเกียรติ</span
                            >
                            <span v-if="item.type_performance == 'etc'"
                              >อื่นๆ ( {{ item.personnel_awardEtc }} )
                            </span>
                          </div>
                        </div>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                color="primary"
                v-if="countWorkHis > 0"
                @click="updatetransference_personnel_38sSubmit((step = 8))"
              >
                ดำเนินการขั้นตอนถัดไป
              </v-btn>
              <v-btn
                text
                outlined
                color="warning"
                @click="updatetransference_personnel_38sSubmit((step = 6))"
              >
                ยกเลิก
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="steps > 9" step="9">
              <div>
               ผลงานที่เคยเสนอเพื่อเลื่อนตำแหน่ง/ระดับ (ถ้ามี)
                <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
              </div>
            </v-stepper-step>

            <v-stepper-content step="9">
              <v-card class="pa-2">
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-textarea
                      outlined
                      v-model="transference_personnel_38s.tp_performance_ever"                  
                      label="(ถ้ามี) : "
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn
                color="primary"              
                @click="updatetransference_personnel_38sSubmit((step = 9))"
              >
                ดำเนินการขั้นตอนถัดไป
              </v-btn>
              <v-btn
                text
                outlined
                color="warning"
                @click="updatetransference_personnel_38sSubmit((step = 7))"
              >
                ยกเลิก
              </v-btn>
            </v-stepper-content>


            <v-stepper-step :complete="steps > 10" step="10">
              <div>
               ผลงานอื่นที่แสดงให้เห็นถึงความรู้ความสามารถพิเศษที่เป็นประโยชน์ต่อการปฏิบัติงาน
                <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
              </div>
            </v-stepper-step>

            <v-stepper-content step="10">
              <v-card class="pa-2">
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-textarea
                      outlined
                      v-model="transference_personnel_38s.tp_performance_special"                  
                      label="(ระบุ) : "
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn
                color="primary"             
                @click="updatetransference_personnel_38sSubmit((step = 10))"
              >
                ดำเนินการขั้นตอนถัดไป
              </v-btn>
              <v-btn
                text
                outlined
                color="warning"
                @click="updatetransference_personnel_38sSubmit((step = 8))"
              >
                ยกเลิก
              </v-btn>
            </v-stepper-content>



            <v-stepper-step :complete="steps > 11" step="11">
              <div>
                เอกสารหลักฐานต่างๆ ประกอบการพิจารณา
                <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
              </div>
            </v-stepper-step>
            <v-stepper-content step="11">
              <v-row>
                <v-col cols="12" sm="12">
                  <v-row class="pa-4 mt-0">
                    <v-col cols="12" sm="6">
                      <v-checkbox
                        v-model="transference_personnel_38s.tp_evidence_info_1"
                        label="สำเนาปริญญาบัตร หรือเอกสารหลักฐานอื่นที่แสดงการสำเร็จการศึกษา"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-checkbox
                        v-model="transference_personnel_38s.tp_evidence_info_2"
                        label="สำเนาระเบียนแสดงผลการศึกษา (Transcript)"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-checkbox
                        v-model="transference_personnel_38s.tp_evidence_info_3"
                        label="สำเนาทะเบียนประวัติข้าราชการ (ก.พ.7) ที่มีรายละเอียดครบถ้วนสมบูรณ์ ตั้งแต่วันที่เริ่มรับราชการจนถึงปัจจุบัน"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-checkbox
                        v-model="transference_personnel_38s.tp_evidence_info_4"
                        label="หนังสือยินยอมการรับเงินเดือนที่ต่ำกว่าเดิม (ถ้ามี)"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-checkbox
                        v-model="transference_personnel_38s.tp_evidence_info_5"
                        label="สำเนาเอกสารหลักฐานที่แสดงผลงานหรือผลการปฏิบัติงาน"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-checkbox
                        v-model="transference_personnel_38s.tp_evidence_info_6"
                        label="สำเนาเอกสารหลักฐานที่แสดงถึงความรู้ความสามารถพิเศษที่เป็นประโยชน์ ต่อการปฏิบัติงานในตำแหน่งที่จะขอเปลี่ยนตำแหน่ง/ย้าย"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-checkbox
                        v-model="transference_personnel_38s.tp_evidence_info_7"
                        label="สำเนาเอกสารหลักฐานอื่นที่เกี่ยวข้อง เช่น
              หนังสือสำคัญการเปลี่ยนชื่อตัว ชื่อสกุล ใบสำคัญการสมรส เป็นต้น (ถ้ามี)"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-checkbox
                        v-model="transference_personnel_38s.tp_evidence_info_8"
                        label="เอกสารหลักฐานอื่นที่ผู้ขอเปลี่ยนตำแหน่ง/ย้าย
              เห็นว่าเป็นประโยชน์ในการพิจารณา"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-checkbox
                        v-model="transference_personnel_38s.tp_evidence_info_9"
                        label=" กรณีบรรจุเป็นข้าราชการในตำแหน่งประเภททั่วไป/ในสายงานที่เริ่มต้นจากระดับ 1 และระดับ 2 ต่อมาได้เปลี่ยนประเภทตำแหน่งเป็นประเภทวิชาการ
              ให้ส่งเอกสารหลักฐาน การเปลี่ยนประเภทตำแหน่ง"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="transference_personnel_38s.tp_evidence_info_10"
                        label="อื่น ๆ (ระบุ)"
                      ></v-checkbox>

                      <v-textarea
                        v-model="transference_personnel_38s.tp_evidence_info_10_detail"
                        :disabled="!transference_personnel_38s.tp_evidence_info_10"
                        label="(ระบุ) : "
                      ></v-textarea>


                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-btn
                color="primary"           
                @click="updatetransference_personnel_38sSubmit((step = 11))"
              >
                ดำเนินการขั้นตอนถัดไป
              </v-btn>
              <v-btn
                text
                outlined
                color="warning"
                @click="updatetransference_personnel_38sSubmit((step = 9))"
              >
                ยกเลิก
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="steps > 12" step="12">
              <div>
                ยืนยันความประสงค์ยืนย้าย
                <v-btn
                  icon
                  rounded
                  color="secondary"
                  target="_blank"
                  @click="previewReportShow()"
                  ><v-icon class="pr-2">mdi-eye</v-icon>
                </v-btn>
              </div>
            </v-stepper-step>
            <v-stepper-content step="12">
              <div>
                <div class="text-center">
                  <h3 class="red--text">
                    * เมื่อกดปุ่มบันทึกและยืนยันข้อมูลแล้ว
                    ท่านจะไม่สามารถแก้ไขข้อมูลใดๆ ได้
                    กรุณาตรวจสอบข้อมูลให้ถูกต้องการกดปุ่ม บันทึก
                  </h3>
                  <h2 class="success--text">
                    * เมื่อกดปุ่มบันทึกแล้วจะปรากฎปุ่มสำหรับพิมพ์แบบเสนอขอย้าย
                  </h2>
                  <v-btn
                    rounded
                    x-large
                    color="secondary"
                    target="_blank"
                    @click="previewReportShow()"
                    ><v-icon class="pr-2">mdi-eye</v-icon>
                    แบบฟอร์มตัวอย่างคำร้องขอย้าย</v-btn
                  >
                </div>
              </div>

              <v-btn
                color="primary"              
                @click="confirmsSubmit()"
              >
                ยืนยันความประสงค์ยืนย้าย
              </v-btn>
              <v-btn
                text
                outlined
                color="warning"
                @click="updatetransference_personnel_38sSubmit((step = 10))"
              >
                ยกเลิก
              </v-btn>
            </v-stepper-content>

            <v-stepper-step step="13">
              ดาวน์โหลดเอกสารประกอบการพิจารณา
            </v-stepper-step>
            <v-stepper-content step="13">
              <v-row no-gutters>
                <v-col cols="12" md="12">
                  <v-card class="pa-2 ma-2">
                    <v-card-title>
                      <div class="font-weight-bold v-size--x-large">
                        <v-icon large left>mdi-electron-framework</v-icon>
                        แบบคำขอเปลี่ยนตำแหน่ง/ย้ายข้าราชการครูและบุคลากรทางการศึกษา  และการย้ายข้าราชการพลเรือนสามัญ
                        ไปบรรจุและแต่งตั้งเป็นข้าราชการครู และบุคลากรทางการศึกษา ตำแหน่งบุคลากรทางการศึกษาอื่นตามมาตรา 38
                        ค.(2)
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <v-btn
                        rounded
                        color="info"
                        dark
                        class="ma-2"
                        :href="'#/user/print_info_38/?tp_id_ref=' + tp_id_ref"
                  target="_blank"
                      >
                        <v-icon>mdi-printer</v-icon>
                        <span>พิมพ์แบบแสดงความประสงค์ขอย้าย</span>
                      </v-btn>
                      <v-spacer />
                      <span class="red--text font-weight-bold">
                        * พิมพ์แบบแสดงความประสงค์ขอย้าย และดำเนินการจัดทำ
                        เพื่อเสนอความเห็นชอบจากผู้บังคับบัญชา รวบรวมเอกสารจัดส่ง กลุ่มงานจัดการงานบุคคล 2 สำนักอำนวยการ 
                      </span>
                    </v-card-text>
                  </v-card>
                </v-col>          
              </v-row>
            </v-stepper-content>
          </v-stepper>
        </base-material-card>

        <v-dialog v-model="previewReport" width="80%">
          <v-card>
            <iframe
              style="width:100%; height: 600px;"
              :src="'#/user/print_info_38_view/?tp_id_ref=' + tp_id_ref"
            ></iframe>
          </v-card>
        </v-dialog>

        <!--addtransference_locationdialog  -->
        <v-layout>
          <v-dialog
            v-model="addtransference_locationdialog"
            persistent
            max-width="80%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                icon="mdi-account-multiple"
                title="เลือกวิทยาลัย"
                class="px-5 py-3"
              >
                รหัสรายการอ้างอิง {{ tp_id_ref }}
              </base-material-card>
              <v-card-text>
                <v-form ref="addtransference_locationform" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex md2>
                        <v-select
                          v-model="addtransference_location.tlsequence_n"
                          :items="order_need"
                          outlined
                          label="ลำดับที่ :"
                        >
                        </v-select>
                      </v-flex>
                      <v-flex md5>
                        <v-autocomplete
                          :items="branch_s"
                          item-text="name_branch"
                          item-value="id_branch"
                          outlined
                          label="สาขาวิชา :"
                          prepend-icon="mdi-account-details"
                          request
                          v-model="addtransference_location.tlid_branch"
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex md5>
                        <v-autocomplete
                          v-model="addtransference_location.tlcollege_code"
                          :items="colleges"
                          item-text="college_name"
                          item-value="college_code"
                          outlined
                          label="สังกัด :"
                          prepend-icon="mdi-flag-letiant"
                          request
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <small>* จำเป็น</small>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  large
                  @click.stop="addtransference_locationdialog = false"
                  ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="success"
                  @click.stop="addtransference_locationSubmit()"
                >
                  <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- V-model deleteuserdialog -->
        <v-layout>
          <v-dialog
            v-model="delettransference_locationdialog"
            persistent
            max-width="40%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="error"
                icon="mdi-delete"
                title="ลบรายการ"
                class="px-5 py-3 "
              >
              </base-material-card>

              <v-card-text class="">
                <v-card>
                  <v-card-text>
                    <v-form
                      ref="deletetransference_locationform"
                      lazy-validation
                    >
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            ยืนยันการลบ
                            <span>
                              {{ edittransference_location.college_name }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  large
                  @click.stop="delettransference_locationdialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="red darken-3"
                  @click.stop="deletetransference_locationSubmit()"
                  dark
                >
                  <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- V-model confirm -->
        <v-layout>
          <v-dialog v-model="confirm_dialog" persistent max-width="40%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="primary"
                icon="mdi-content-save"
                title="ยืนยันรายการ"
                class="px-5 py-3 "
              >
              </base-material-card>

              <v-card-text class="">
                <v-card>
                  <v-card-text>
                    <v-form
                      ref="deletetransference_locationform"
                      lazy-validation
                    >
                      <v-container grid-list-md>
                        <v-alert outlined type="error" prominent border="left">
                          <h2>
                            เตือน
                          </h2>
                          <h3>
                            หากกดปุ่มบันทึกท่านไม่สามารถ แก้ไขข้อมูลใดๆ ได้แล้ว
                            กรุณาตรวจสอบให้ถูกต้องก่อนการบันทึก
                          </h3>
                        </v-alert>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large rounded @click.stop="confirm_dialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="primary"
                  @click.stop="updatetransference_personnel_38sSubmit(step = 12)"
                  dark
                  rounded
                >
                  <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันส่งคำร้อง
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- V-model Alert Work -->
        <v-layout>
          <v-dialog v-model="rest_Daydialog" persistent max-width="50%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="error"
                icon="mdi-content-save"
                title="ปิดระบบ"
                class="px-5 py-3 "
              >
              </base-material-card>

              <v-card-text class="">
                <v-container grid-list-md>
                  <v-alert outlined type="error" prominent border="left">
                    <h3>
                      กรุณาเข้าทำระบบใหม่ในวันเวลาราชการ
                    </h3>
                  </v-alert>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn fab text large rounded @click.stop="closePage()"
                  ><v-icon dark>mdi-close</v-icon></v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- V-model personnel_award_dialog -->
        <v-layout>
          <v-dialog v-model="personnel_award_dialog" max-width="80%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="primary"
                icon="mdi-content-save"
                title="เลือกผลงาน/รางวัล"
                class="px-5 py-3 "
              >
              </base-material-card>

              <v-card class="mt-2">
                <v-card-text>
                  <v-form ref="personnel_awardform" lazy-validation>
                    <v-container grid-list-md>
                      <v-data-table
                        color="success"
                        :loading="loading"
                        :headers="headers"
                        :items="personnel_awards"
                        :search="search"
                      >
                        <template v-slot:item="{ item, index }">
                          <tr>
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">
                              {{ item.personnel_awardYear }}
                            </td>
                            <td class="text-center">
                              {{ item.performance_name }}
                            </td>
                            <td class="text-center">
                              {{ item.performance_location }}
                            </td>
                            <td class="text-center">
                              {{
                                item.performance_time
                                  | moment("add", "543 years")
                                  | moment("D MMMM YYYY")
                              }}
                            </td>
                            <td class="text-center">
                              <span v-if="item.award_level == 'college'"
                                >ระดับวิทยาลัย</span
                              >
                              <span v-if="item.award_level == 'provincial'"
                                >ระดับสำนักงานอาชีวศึกษาจังหวัด</span
                              >
                              <span v-if="item.award_level == 'sector'"
                                >ระดับภาค</span
                              >
                              <span v-if="item.award_level == 'vocational'"
                                >ระดับอาชีวศึกษา</span
                              >
                              <span v-if="item.award_level == 'nation'"
                                >ระดับชาติ</span
                              >
                              <span v-if="item.award_level == 'word'"
                                >ระดับโลก</span
                              >
                            </td>
                            <td class="text-center">
                              <span v-if="item.type_performance == 'skill'"
                                >การแข่งขันทักษะ</span
                              >
                              <span v-if="item.type_performance == 'innovation'"
                                >การประกวดสิ่งประดิษฐ์</span
                              >
                              <span v-if="item.type_performance == 'award'"
                                >รางวัลเชิดชูเกียรติ</span
                              >
                              <span v-if="item.type_performance == 'etc'"
                                >อื่นๆ ( {{ item.personnel_awardEtc }} )
                              </span>
                            </td>
                            <td class="text-center">
                              <v-btn fab dark color="green">
                                <v-icon
                                  @click.stop="
                                    updatetransference_personnel_38s_award(
                                      item.id_pa
                                    )
                                  "
                                >
                                  mdi-checkbox-marked-circle
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>

                        <v-alert
                          slot="no-results"
                          :value="true"
                          color="error"
                          icon="mdi-alert"
                          >ไม่พบผลลัพธ์ "{{ search }}"
                          ที่คุณกำลังค้นหา.</v-alert
                        >
                      </v-data-table>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-col>
    </v-row>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </v-container>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";
export default {
  name: "HrvecTransferencePersonnel",
  components: { FlipCountdown },
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      isHidden: false,
      timestamp: "",
      steps: 1,
      colleges: [],
      personnel_search: {},
      show_personnel: [],
      branch_s: [],
      transference_locations: [],
      search: "",
      isHidden_service_gov: false,
      service_gov: false,
      addtransference_locationdialog: false,
      delettransference_locationdialog: false,
      rest_Daydialog: false,
      Search_Personneldialog: false,
      confirm_dialog: false,
      user: {},
      transference_personnel_38: [],
      transference_personnel_38s: [],

      addtransference_personnel_38: {
        reason_1: false,
        reason_2: false,
        reason_3: false,
        reason_4: false,
        subject_1: "-",
        subject_2: "-",
        subject_3: "-",
        subject_4: "-",
        ser_college_code: "-",
        ser_order: "-",
        ser_reason_help: "-",
        teaching_status: "teacher",
        service_status: "not_service",
        in_preparation: "no_prepare",
        on_study_leave: "no_leave",
        not_conditions: "not_con",
        switch_position: "-",
        perform_other_duties: "-"
      },

      addtransference_location: {},
      edittransference_location: [],
      provinces1: [],
      provinces2: [],
      provinces3: [],
      provinces4: [],

      provices_sh: [],
      prefectures1: [],
      prefectures2: [],
      prefectures3: [],
      prefectures4: [],

      personnel_educations: [],
      personnel_education_check: [],
      district1: [],
      district2: [],
      district3: [],
      district4: [],
      district_post_s: [],
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      order_need: [1, 2, 3],
      header_trans: [
        { text: "ลำดับ", align: "center", value: "tlsequence_n" },
        { text: "รหัสอ้างอิง", align: "center", value: "tltp_id_ref" },
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "วิทยาลัย", align: "left", value: "college_name" },
        { text: "ยกเลิก", align: "center", value: "action" }
      ],
      education_s: [
        {
          title: "ต่ำกว่าปริญญาตรี",
          faculty: "",
          branch: "",
          color: "deep-purple lighten-1"
        },
        {
          title: "ปริญญาตรี",
          faculty: "บธ.บ.",
          branch: "ระบบสารสนเทศทางคอมพิวเตอร์",
          color: "green"
        },
        {
          title: "ปริญญาโท",
          faculty: "",
          branch: "",
          color: "deep-purple lighten-1"
        },
        {
          title: "ปริญญาเอก",
          faculty: "",
          branch: "",
          color: "deep-purple lighten-1"
        }
      ],
      headers: [
        { text: "#", align: "center", value: "index" },
        { text: "ปี", align: "center", value: "personnel_awardYear" },
        { text: "ผลงาน รางวัล", align: "center", value: "performance_name" },
        { text: "สถานที่", align: "center", value: "performance_location" },
        { text: "เมื่อวันที่", align: "center", value: "performance_time" },
        { text: "ระดับ", align: "center", value: "award_level" },
        { text: "ประเภท", align: "center", value: "type_performance" },
        {
          text: "เลือก",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        }
      ],
      isEditing: null,
      model: null,
      show1: false,
      pagination: {},
      personnel_marriages: [],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      personnel_temporarys: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      periods: [],
      period_enable: "1",
      updatetransference_personnel_38s: {},
      periodTeachers: [],
      labels: {
        days: "วัน",
        hours: "ชั่วโมง",
        minutes: "นาที",
        seconds: "วินาที"
      },
      transference_locationsCF: {},
      previewReport: false,
      personnel_awards: [],
      personnel_award_dialog: false,
      personnel_award_id: [],
      man_power_38: [],
      man_power_38ck: [],
      personnel_work_historys: [],
      countWorkHis: ""
    };
  },

  async mounted() {
    /*  await this.checkDay(); */

    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    let result_branch;
    result_branch = await this.$http.post("branch.php", {
      ApiKey: this.ApiKey
    });
    this.branch_s = result_branch.data;

    let result;
    result = await this.$http.post("collegetype.php", {
      ApiKey: this.ApiKey
    });
    this.collegetypes = result.data;

    result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });
    this.colleges = result.data;

    result = await this.$http.post("region.php", {
      ApiKey: this.ApiKey
    });
    this.regions = result.data;

    let result_user;
    result_user = await this.$http.post("personnel_temporary.php", {
      ApiKey: this.ApiKey,
      id_card: userSession.id_card
    });
    this.user = result_user.data;

    await this.provinceQueryAll1();
    await this.provinceQueryAll2();
    await this.provinceQueryAll3();
    await this.provinceQueryAll4();
    await this.periodQueryAll();
    await this.transference_personnel_38All();
    await this.transference_locationQueryAll();
    await this.personnel_educationsQueryAll();
    await this.personnel_educations_checkQueryAll();
    await this.timeCheck(); /* ย้ายครู */
    await this.educationCheck();
    //await this.get_gov_Age_yearCheck();

    await this.personnel_awardsQueryAll();
    await this.man_power_38Query();
    await this.personnel_work_historysQueryAll();
  },

  methods: {
    async personnel_work_historysQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_work_history.php", {
          ApiKey: this.ApiKey,
          id_card: userSession.id_card
        })
        .finally(() => (this.loading = false));
      this.personnel_work_historys = result.data;
      let count = 0;
      let data = this.personnel_work_historys;
      data.forEach(value => {
        count += 1;
      });
      this.countWorkHis = count;
    },

    async man_power_38Query() {
      let result = await this.$http.post("man_power_38.php", {
        ApiKey: this.ApiKey
      });
      this.man_power_38 = result.data;
    },

    async man_powerCk() {
      let result = await this.$http.post("man_power_38.php", {
        ApiKey: this.ApiKey,
        mp_id_position: this.transference_personnel_38s.tp_positonid_select
      });
      this.man_power_38ck = result.data;
      ;
    },

    async personnel_awardQuery() {
      this.personnel_award_dialog = true;
    },
    async personnel_awardsQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_award.php", {
          ApiKey: this.ApiKey,
          personnel_awardid_card: userSession.id_card
        })
        .finally(() => (this.loading = false));
      this.personnel_awards = result.data;

      let datacheck = this.personnel_awards;
      let count = 0;
      datacheck.forEach(value => {
        count += 1;
      });
      if (count == 0) {
        Swal.fire({
          icon: "error",
          title: "ท่านยังไม่ได้กรอกข้อมูล ผลงาน/รางวัล/การได้รับการยกย่อง",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          /* if (result.isConfirmed) {
            this.$router.push("/personnel_award");
          } */
        });
      }
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    async get_gov_Age_yearCheck() {
      if (this.get_gov_Age_year < 1) {
        Swal.fire({
          icon: "error",
          title:
            "ตามนัยหนังสือสำนักงาน ก.ค.ศ. ที่ ศธ 0206.4/ว 18 ลงวันที่ 17 สิงหาคม 2556 คุณสมบัติของผู้ขอย้าย จะต้องดำรงตำแหน่งในหน่วยงานการศึกษาปัจจุบันไม่น้อยกว่า 12 เดือน",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          /*    if (result.isConfirmed) {
            this.$router.push("/UserProfile");
          } */
        });
      }
    },

    async educationCheck() {
      if (this.personnel_education_check.count_ed === "0") {
        Swal.fire({
          icon: "error",
          title: "ให้ดำเนินการบันทึกข้อมูลคุณวุฒิการศึกษาของท่านเรียบร้อย",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          /*    if (result.isConfirmed) {
            this.$router.push("/personnel_education");
          } */
        });
      }
    },

    async checkDay() {
      if (this.checkToday == "0" || this.checkToday == "6") {
        this.rest_Daydialog = true;
      }
    },
    async closePage() {
      this.$router.push("/UserProfile");
    },
    async previewReportShow() {
      this.previewReport = true;
    },
    async periodQueryAll() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable: "1",
        period_id: "3801"
      });
      this.periods = result_period.data;
      if (
        this.periods.period_enable === "1" &&
        this.periods.period_type === "38"
      ) {
      } else {
        /* this.$router.push("/UserProfile"); */
        Swal.fire({
          icon: "error",
          title: "ระบบได้ปิดทำการเสนอย้ายแล้วในขณะนี้",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          /*  if (result.isConfirmed) {
            this.$router.push("/UserProfile");
          } */
        });
      }
    },

    async timeCheck() {
      let dateNow = new Date();
      let dateStop = new Date(this.periods.period_stop + " 16:30:00");
      this.dateNows = dateNow.getTime();
      this.dateStops = dateStop.getTime();
      if (this.dateNows > this.dateStops) {
        await this.periodTeacherUpdate();
        await this.periodQueryAll();
      } else {
      }
    },

    async periodTeacherUpdate() {
      this.periods.ApiKey = this.ApiKey;
      this.periods.period_enable = "0";
      await this.$http.post("period.update.php", this.periods);
    },

    async personnel_educationsQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_education.php", {
          ApiKey: this.ApiKey,
          id_card: userSession.id_card
        })
        .finally(() => (this.loading = false));
      this.personnel_educations = result.data;
    },

    async personnel_educations_checkQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_education.check.php", {
          ApiKey: this.ApiKey,
          id_card: userSession.id_card
        })
        .finally(() => (this.loading = false));
      this.personnel_education_check = result.data;
    },

    async OnEnter() {
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_card: this.addtransference_personnel_38.switch_position
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },
    async OnEnterUpdate() {
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_card: this.transference_personnel_38s.switch_position
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },
    async transference_personnel_38All() {
      this.loading = true;
      let result = await this.$http
        .post("transference_personnel_38.php", {
          ApiKey: this.ApiKey,
          tp_id_ref: this.tp_id_ref
        })
        .finally(() => (this.loading = false));
      this.transference_personnel_38s = result.data;

      let stepsep = this.transference_personnel_38s.tp_steps;
      if (stepsep >= 1) {
        this.steps = parseInt(this.transference_personnel_38s.tp_steps) + 1;
      } else {
        this.steps = 1;
      }
    },

    async transference_locationQueryAll() {
      let result = await this.$http.post("transference_location.php", {
        ApiKey: this.ApiKey,
        tp_id_ref: this.tp_id_ref
      });
      this.transference_locations = result.data;
    },

    //First >> Insert transference Location
    async addtransference_locationdialogsubmit() {
      this.addtransference_location = {};
      this.addtransference_locationdialog = true;
    },

    async Search_Personnel() {
      this.personnel_select = {};
      this.Search_Personneldialog = true;
    },

    async addtransference_locationSubmit() {
      await this.timeCheck();
      await this.periodQueryAll();
      if (this.$refs.addtransference_locationform.validate()) {
        this.addtransference_location.ApiKey = this.ApiKey;
        this.addtransference_location.tltp_id_ref = this.tp_id_ref;
        this.addtransference_location.tlid_card = this.user.id_card;
        let result = await this.$http.post(
          "transference_location.insert.php",
          this.addtransference_location
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_locationQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title:
              "บันทึกข้อมูลผิดพลาด รายการลำดับ หรือ ชื่อหน่วยงานแหน่งใหม่ซ้ำ",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.addtransference_locationdialog = false;
      }
    },

    //Confirm data

    async confirmsSubmit() {
      this.confirm_dialog = true;
    },

    //Delete transference Location
    async transference_locationDelete(id_tfl) {
      let result = await this.$http.post("transference_location.php", {
        ApiKey: this.ApiKey,
        id_tfl: id_tfl
      });
      this.edittransference_location = result.data;
      this.delettransference_locationdialog = true;
    },
    async deletetransference_locationSubmit() {
      if (this.$refs.deletetransference_locationform.validate()) {
        this.edittransference_location.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "transference_location.delete.php",
          this.edittransference_location
        );
        if (result.data.status == true) {
          this.transference_location = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_locationQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการลบผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.delettransference_locationdialog = false;
      }
    },

    async deletetransference_personnel_38sSubmit() {
      Swal.fire({
        title: "ยกเลิกการยืนย้าย",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.transference_personnel_38s.ApiKey = this.ApiKey;
          let result = await this.$http.post(
            "transference_personnel_38.delete.php",
            this.transference_personnel_38s
          );

          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.transference_personnel_38All();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    // Add transference_personnel_38
    async addtransference_personnel_38Submit() {
      Swal.fire({
        title: "ท่านประสงค์ยืนย้าย และดำเนินการในขั้นตอนถันไป",
        text: "บันทึกรายการชั่วคราว",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          await this.timeCheck();
          await this.periodQueryAll();
          this.addtransference_personnel_38.ApiKey = this.ApiKey;
          this.addtransference_personnel_38.tp_id_ref = this.tp_id_ref;
          this.addtransference_personnel_38.tp_time_s = this.periods.period_times;
          this.addtransference_personnel_38.tp_year_s = this.periods.period_year;
          this.addtransference_personnel_38.tp_id_card = this.user.id_card;
          this.addtransference_personnel_38.tp_ageUser = this.get_ageUser;
          this.addtransference_personnel_38.tp_date_time = this.date_today;
          this.addtransference_personnel_38.tp_age_time = this.get_gov_Age;
          this.addtransference_personnel_38.tp_age_app_time = this.get_Appoint_Age;
          this.addtransference_personnel_38.tp_type_move = this.user.user_status;
          this.addtransference_personnel_38.tp_college_code_now = this.user.college_code;
          this.addtransference_personnel_38.tp_steps = 1;

          let result = await this.$http.post(
            "transference_personnel_38.insert.php",
            this.addtransference_personnel_38
          );

          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.transference_personnel_38All();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          /* this.loadOnce() */
          this.addtransference_locationdialog = false;
          this.confirm_dialog = false;
        }
      });
    },

    async updatetransference_personnel_38s_award(id_pa) {
      let result = await this.$http.post("personnel_award.php", {
        ApiKey: this.ApiKey,
        id_pa: id_pa
      });
      this.personnel_award_id = result.data;
      this.personnel_award_dialog = false;
    },
    async updatetransference_personnel_38sSubmit(step) {
      await this.sweetAlertLoading();
      await this.timeCheck();
      await this.periodQueryAll();
      this.transference_personnel_38s.ApiKey = this.ApiKey;
      this.transference_personnel_38s.tp_age_time = this.get_gov_Age;
      this.transference_personnel_38s.tp_age_app_time = this.get_Appoint_Age;
      this.transference_personnel_38s.tp_steps = step;
      if (step == 2) {
        this.transference_personnel_38s.tp_positon_select = this.man_power_38ck.mp_position;
        this.transference_personnel_38s.tp_positonunder_select = this.man_power_38ck.mp_college_code;
      }

      if (step == 12) {
        this.transference_personnel_38s.tp_date_time = new Date();
     
      }


      let result = await this.$http.post(
        "transference_personnel_38.update.php",
        this.transference_personnel_38s
      );
      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.transference_personnel_38All();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.transference_personnel_38All();
      this.confirm_dialog=false
      /* this.loadOnce(); */
      Swal.close();
    },


    async clear_tech() {
      //this.$v.$reset();
      this.addtransference_personnel_38.subject_1 = "-";
      this.addtransference_personnel_38.subject_2 = "-";
      this.addtransference_personnel_38.subject_3 = "-";
      this.addtransference_personnel_38.subject_4 = "-";
    },
    async clear_gov_service() {
      //this.$v.$reset();
      this.addtransference_personnel_38.ser_college_code = "-";
      this.addtransference_personnel_38.ser_order = "-";
      this.addtransference_personnel_38.ser_date = "-";
      this.addtransference_personnel_38.ser_time_year = "-";
      this.addtransference_personnel_38.ser_time_month = "-";
      this.addtransference_personnel_38.ser_reason_help = "-";
      this.transference_personnel_38s.ser_college_code = "";
      this.transference_personnel_38s.ser_order = "";
      this.transference_personnel_38s.ser_date = "";
      this.transference_personnel_38s.ser_time_year = "";
      this.transference_personnel_38s.ser_time_month = "";
      this.transference_personnel_38s.ser_reason_help = "";
    },
    loadOnce() {
      location.reload();
    },
    async provinceQueryAll1() {
      let result = await this.$http.post("province.php");
      this.provinces1 = result.data;
    },
    async provinceQueryAll2() {
      let result = await this.$http.post("province.php");
      this.provinces2 = result.data;
    },
    async provinceQueryAll3() {
      let result = await this.$http.post("province.php");
      this.provinces3 = result.data;
    },
    async provinceQueryAll4() {
      let result = await this.$http.post("province.php");
      this.provinces4 = result.data;
    },

    async prefectureQueryAll1(province_ID) {
      let result = await this.$http.post("prefecture.php", {
        province_ID: province_ID
      });
      this.prefectures1 = result.data;
    },

    async prefectureQueryAll2(province_ID) {
      let result = await this.$http.post("prefecture.php", {
        province_ID: province_ID
      });
      this.prefectures2 = result.data;
    },
    async prefectureQueryAll3(province_ID) {
      let result = await this.$http.post("prefecture.php", {
        province_ID: province_ID
      });
      this.prefectures3 = result.data;
    },
    async prefectureQueryAll4(province_ID) {
      let result = await this.$http.post("prefecture.php", {
        province_ID: province_ID
      });
      this.prefectures4 = result.data;
    },

    async districtQueryAll1(prefecture_ID) {
      let result = await this.$http.post("districts.php", {
        prefecture_ID: prefecture_ID
      });
      this.district1 = result.data;
    },
    async districtQueryAll2(prefecture_ID) {
      let result = await this.$http.post("districts.php", {
        prefecture_ID: prefecture_ID
      });
      this.district2 = result.data;
    },
    async districtQueryAll3(prefecture_ID) {
      let result = await this.$http.post("districts.php", {
        prefecture_ID: prefecture_ID
      });
      this.district3 = result.data;
    },
    async districtQueryAll4(prefecture_ID) {
      let result = await this.$http.post("districts.php", {
        prefecture_ID: prefecture_ID
      });
      this.district4 = result.data;
    },

    async district_PostQueryAll1(district_ID) {
      let result = await this.$http.post("districts_post.php", {
        district_ID: district_ID
      });
      this.district_post_s = result.data;
    },
    async district_PostQueryAll2(district_ID) {
      let result = await this.$http.post("districts_post.php", {
        district_ID: district_ID
      });
      this.district_post_s = result.data;
    },
    async district_PostQueryAll3(district_ID) {
      let result = await this.$http.post("districts_post.php", {
        district_ID: district_ID
      });
      this.district_post_s = result.data;
    },
    async district_PostQueryAll4(district_ID) {
      let result = await this.$http.post("districts_post.php", {
        district_ID: district_ID
      });
      this.district_post_s = result.data;
    }
  },
  computed: {
    tp_id_ref() {
      let tp_id_ref_cr;
      tp_id_ref_cr =
        this.periods.period_times +
        this.periods.period_year +
        this.user.id_card;
      return tp_id_ref_cr;
    },

    user_status_type() {
      let user_status = this.user.user_status;
      let result;
      if (user_status == "teacher") {
        result = "teacher";
      } else if (user_status == "director") {
        result = "manage";
      } else if (user_status == "se_director") {
        result = "manage";
      }
      return result;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    month_now() {
      const d = new Date();
      let month = d.getMonth() + 1;
      return month;
    },
    year_s() {
      const d = new Date();
      let year = d.getFullYear() + 543;
      return year;
    },

    marital_status() {
      let marital_status = this.user.marital_status;
      let marital_result;
      if (marital_status == "single") {
        marital_result = "โสด";
      } else if (marital_status == "married") {
        marital_result = "สมรส";
      } else if (marital_status == "widow") {
        marital_result = "หม้าย";
      } else if (marital_status == "divorce") {
        marital_result = "หย่า";
      } else if (marital_status == "separate") {
        marital_result = "แยกกันอยู่";
      }
      return marital_result;
    },
    date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },

    date_appoin_ch() {
      let monthNames = [
        "",
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม"
      ];
      let day = this.user.appoin_day;
      let month = monthNames[parseInt(this.user.appoin_month)];
      let year = this.user.appoin_year;
      let years = parseInt(year);
      let today = day + " " + month + " " + years;
      return today;
    },

    date_today_cal() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },

    get_Appoint_Age() {
      let today = new Date(this.periods.period_cal_end);
      let DOB = new Date(this.user.date_app_now);

      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = 0;
        else months = totalMonths % 12;
      }
      let age = years + " ปี " + months + " เดือน " + days + " วัน";
      return age;
    },

    get_ageUser() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(
        parseInt(this.user.brith_year) -
          543 +
          "-" +
          this.user.brith_month +
          "-" +
          this.user.brith_day
      );
      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = 0;
        else months = totalMonths % 12;
      }
      let age = years + " ปี";
      return age;
    },

    get_gov_Age() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(
        parseInt(this.user.appoin_year) -
          543 +
          "-" +
          this.user.appoin_month +
          "-" +
          this.user.appoin_day
      );
      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = 0;
        else months = totalMonths % 12;
      }
      let age = years + " ปี " + months + " เดือน " + days + " วัน";
      return age;
    },

    get_gov_Age_year_service() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(this.addtransference_personnel_38.ser_date);
      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = 0;
        else months = totalMonths % 12;
      }
      let age = years;
      return age;
    },

    get_gov_Age_month_service() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(this.addtransference_personnel_38.ser_date);
      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = 0;
        else months = totalMonths % 12;
      }
      let age = months;
      return age;
    },

    get_gov_Age_year() {
      let today = new Date(this.periods.period_cal_end);
      let DOB = new Date(this.user.date_app_now);

      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = 0;
        else months = totalMonths % 12;
      }
      let age = years;
      return age;
    },

    get_gov_Age_yearTypeSelect() {
      let today = new Date(this.periods.period_cal_end);
      let DOB = new Date(
        parseInt(this.user.appoin_year) -
          543 +
          "-" +
          this.user.appoin_month +
          "-" +
          this.user.appoin_day
      );

      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = 0;
        else months = totalMonths % 12;
      }
      let age = years;
      return age;
    },

    checkToday() {
      const date = new Date();
      let day = date.getDay();
      return day;
    },

    editCmemberProvinceChange() {
      return this.transference_personnel_38s.reason_2_domicile;
    },
    editCmemberprefectureChange() {
      return this.transference_personnel_38s.reason_2_prefecture;
    },
    editCmemberdistrictChange() {
      return this.transference_personnel_38s.reason_2_district;
    },

    editCmemberProvince3Change() {
      return this.transference_personnel_38s.reason_3_domicile;
    },
    editCmemberprefecture3Change() {
      return this.transference_personnel_38s.reason_3_prefecture;
    },
    editCmemberdistrict3Change() {
      return this.transference_personnel_38s.reason_3_district;
    },

    editCmemberProvince1Change() {
      return this.transference_personnel_38s.reason_1_domicile;
    },
    editCmemberprefecture1Change() {
      return this.transference_personnel_38s.reason_1_prefecture;
    },
    editCmemberdistrict1Change() {
      return this.transference_personnel_38s.reason_1_district;
    },

    editCmemberProvince1chChange() {
      return this.transference_personnel_38s.reason_1_childdomicile;
    },
    editCmemberprefecture1chChange() {
      return this.transference_personnel_38s.reason_1_childprefecture;
    },
    editCmemberdistrict1chChange() {
      return this.transference_personnel_38s.reason_1_childdictrict;
    }
  },

  watch: {
    async date_transference(newVal, oldVal) {
      await this.transference_personnel_38All();
    },

    async editCmemberProvinceChange() {
      this.prefectureQueryAll2(
        this.transference_personnel_38s.reason_2_domicile
      );
    },
    async editCmemberprefectureChange() {
      this.districtQueryAll2(
        this.transference_personnel_38s.reason_2_prefecture
      );
    },
    async editCmemberdistrictChange() {
      this.district_PostQueryAll2(
        this.transference_personnel_38s.reason_2_district
      );
    },

    async editCmemberProvince3Change() {
      this.prefectureQueryAll3(
        this.transference_personnel_38s.reason_3_domicile
      );
    },
    async editCmemberprefecture3Change() {
      this.districtQueryAll3(
        this.transference_personnel_38s.reason_3_prefecture
      );
    },
    async editCmemberdistrict3Change() {
      this.district_PostQueryAll3(
        this.transference_personnel_38s.reason_3_district
      );
    },

    async editCmemberProvince1Change() {
      this.prefectureQueryAll1(
        this.transference_personnel_38s.reason_1_domicile
      );
    },
    async editCmemberprefecture1Change() {
      this.districtQueryAll1(
        this.transference_personnel_38s.reason_1_prefecture
      );
    },
    async editCmemberdistrict1Change() {
      this.district_PostQueryAll1(
        this.transference_personnel_38s.reason_1_district
      );
    },

    async editCmemberProvince1chChange() {
      this.prefectureQueryAll4(
        this.transference_personnel_38s.reason_1_childdomicile
      );
    },
    async editCmemberprefecture1chChange() {
      this.districtQueryAll4(
        this.transference_personnel_38s.reason_1_childprefecture
      );
    },
    async editCmemberdistrict1chChange() {
      this.district_PostQueryAll4(
        this.transference_personnel_38s.reason_1_childdictrict
      );
    }
  }
};
</script>
